.events-container {
    display:flex;
    width:92vw;
    margin:0 auto;
    border-bottom: 1px solid rgba(38, 26, 33, 0.3);
    padding-bottom: 20px;
    padding-top:20px;
    justify-content: space-between;
    /* align-items:center; */   
}

@media screen and (min-width:1200px) and (max-width:1599px){
    .events-container {
        width:80vw;
        
    }
}
@media screen and (min-width:1600px){
    .events-container {
        width:65vw;
    }
}

.events-media-container {
    display: flex;
    /* width:40%; */
    width:430px;
    height:230px;
}

.events-date-container {
    display:flex;
    align-items:center;
    flex-direction:column;
    width:7%;
}

.events-description-container {
    display:flex;
    flex-direction: column;
    width:43%;
    /* margin: 0 auto; */
}

@media screen and (min-width:850px) and (max-width:1000px){
    .events-container {
        /* flex-direction: column; */
    }

    .events-media-container {
        width:330px;
        height:183px;
    }

    .events-date-container {
        display:flex;
        flex-direction:column;
        width:5%;
    }

    .events-description-container {
        /* width:100%; */
    }
}

@media screen and (max-width:849px){
    .events-container {
        flex-direction: column;
    }

    .events-media-container {
        width:50vw;
        margin:0 auto;
        height:auto;
    }

    .events-date-container {
        display:flex;
        width:40vw;
        margin:10px auto;
    }

    .events-description-container {
        width:80vw;
        margin:0 auto
    }
}