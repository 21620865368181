.navbar {
  height: 20px;
  display: flex;
  flex-direction: row;
  padding:32px 0px 16px 0px;
  width:100vw;
  position:relative;
  z-index:2;
}

.navbar-items {
  display: flex;
  width:100vw;
  justify-content:center;
}

.navbar-link {
  padding-right: 20px;
  font-weight:700;
  overflow:hidden;
}

.link-animation {
  transition-duration: .55s;
}

.link-animation:hover {
  transform:translateY(-66%);
  transition-duration: .55s;
}

.navbar-link-item {
  text-decoration: none;
  color: #382630;
}

.navbar-link-media {
  padding-right: 20px;
  position:relative;
  z-index: 3;
}

.navbar-logo {
  margin-left: auto;
}
