.marquee {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  height: 51px;
  position: absolute;
  bottom: 10px;
}

.marquee p {
    display: inline-block;
    margin-right: 30px;
    animation: marquee 100s linear 4s infinite;
    -webkit-animation: marquee 100s linear 4s infinite;
}

.marquee .second {
  animation-delay: 52s;
  -webkit-animation-delay: 52s;
}

@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-10000px, 0); }
}

@-webkit-keyframes marquee {
    0%   { -webkit-transform: translate(0, 0); }
    100% { -webkit-transform: translate(-10000px, 0); }
}

