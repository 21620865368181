input.contactform::-webkit-input-placeholder { /* WebKit browsers */
    color: #382630;
}

input.contactform::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #382630;
}
input.contactform:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #382630;
}

textarea.contactform:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #382630;
}

textarea.contactform::-webkit-input-placeholder { /* WebKit browsers */
    color: #382630;
}

textarea.contactform::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #382630;
}

input.checkbox {
  appearance:none;
  height:18px;
  width:18px;
  border: 1px solid rgba(56, 38, 48, 0.82);
  cursor: pointer;
}

input.checkbox:checked {
  background-image: url('../../../images/Shared/check.svg');
}

.contact-button {
  padding:10px 30px;
  border-radius: 4px;
  outline:0;
  background-color: #27CCE1;
  color: #FFFFFF;
  border: 1px solid #27CCE1;
  font-weight:700;
  margin-left:auto;
  margin-right:auto;
  margin-top:20px;
  transition-duration: 500ms;
  cursor: pointer;
  background-image:linear-gradient(-134deg, #27B1E1 0%, #27DBDE 94%, #27CCE1 100%);

}

.contact-button:hover {
  background-color: #FFFFFF;
  background-image:none;
  color:#27CCE1;
  transition-duration: 500ms;
  cursor: pointer;
  border:0;
}