input.subscription::-webkit-input-placeholder {
  color:#CDBFBF;
}

input.subscription::-moz-placeholder {
  color:#CDBFBF;
}

input.subscription:-ms-input-placeholder {
  color: #382630;
}

input.press-subscribe::-webkit-input-placeholder {
  color:#382630;
}

input.press-subscribe::-moz-placeholder {
  color:#382630;
}