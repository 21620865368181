.press-container {
    display:flex;
    flex-direction: row;
    margin-top:50px;
}

.press-release-container {
    display: flex;
    width:70vw;
    flex-direction: column;
}

@media screen and (max-width:800px){
    .press-release-container {
        width:100vw;
        margin:0 auto;
    }
}

.press-side-container {
    display:flex;
    width:30vw;
    flex-direction: column;
}

.hero-flex-text {
    display: flex;
    flex-direction: column;
    height:100%;
    justify-content: center;
}

input.press-subscribe::-webkit-input-placeholder {
    color:#rgba(56, 38, 48, .7);
  }
  
  input.press-subscribe::-moz-placeholder {
    color:#rgba(56, 38, 48, .7);
  }

label.press-subscribe {
      margin-bottom:10px;
  }
  