.about-flex-container {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  width:990px;
  margin:50px auto;
  justify-content: center;
}

.angel-list-button {
  font-size:16px;
  color: #FFFFFF;
  letter-spacing: -0.41px;
  text-align: center;
  background-image: linear-gradient(-134deg, #27B1E1 0%, #27DBDE 94%, #27CCE1 100%);
  border-radius: 20px;
  border:1px solid #27CCE1;
  outline:0;
  padding: 8px 28px;
  margin-top:30px;
  transition-duration: 500ms;
  cursor:pointer;

}

.angel-list-button:hover {
  color:#27CCE1;
  cursor:pointer;
  background-image:none;
  background-color: #FFFFFF;
  border:0;
  transition-duration: 500ms;
}


.advisor-flex-container {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  width:660px;
  margin:50px auto;
  justify-content: center;
}


.title-flex-container {
  width:80vw;
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

@media (max-width:1000px){
  .about-flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width:100vw;
  }

  .advisor-flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width:100vw;
  }
}

.about-hero-container {
  display: flex;
  height:100%;
  justify-content: space-between;
}

.about-hero-left {
  width: 45%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height:100%;
}

.about-hero-right {
  width:45%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height:100%;
}

.about-hero-left-content {
  display: flex;
  flex-direction: column;
}

.about-hero-right-content {
  display: flex;
  flex-direction: column;
  width:350px;
}

.about-hero-med-small {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:100%;
}