.option-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height:50px;
    padding: 10px 0;
}

.contact-button {
    padding:20px 25px;
    background-color: #27CCE1;
    color:white;
    font-size:16px;
    display:block;
    margin:0 auto;
    outline:0;
    border:1px solid #27CCE1;
    text-decoration: none;
    transition-duration: 500ms;
    cursor: pointer;
    font-weight:700;
}

.contact-button:hover {
    background-color: #FFFFFF;
    color:#27CCE1;
}



tr:nth-child(even) {
    background-color: #F8FAFC;
  }