/* Section 1 */
.landing-flex-container {
  display:flex;
  justify-content: center;
  position:relative;
  bottom:calc(100vh - 80px);
  height:calc(100vh - 80px);
}

.landing-flex-left-side {
  display:flex;
  justify-content: flex-end;
  width:25vw;
  margin-right:5vw;
  align-items: center;
  height:100%;
}

.landing-flex-right-side {
  display:flex;
  justify-content: flex-start;
  width:25vw;
  margin-left:2vw;
  align-items: center;
}

.hero-text {
  font-size:100px;
}



/*Smaller Screens*/
@media only screen and (min-width:600px) and (max-width: 650px){
  .landing-flex-left-side {
    width:auto;
  }

  .landing-flex-right-side {
    width:auto;
  }
}

@media (max-width:599px){
  .landing-flex-container {
    flex-direction:column;
    justify-content: flex-start;
  }

  .landing-flex-left-side {
    align-items:flex-start;
    justify-content: center;
    width:auto;
    height:auto;
  }

  .landing-flex-right-side {
    align-items:stretch;
    justify-content: center;
    width:auto;
  }

  .hero-text {
    text-align: center;
  }
}


/*IE*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
  .hero-text{
      font-size:81px;
  }
}


/*Social Bar*/
.social-bar a, a:visited {
  color:white;
}


/*Section2*/
.flex-home-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex-column-container {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #D8D8D8;
}

.flex-row-container {
  display: flex;
  flex-direction: row;
  position: relative;
  top:50%;
  transform: translateY(-50%);
  color:#261A21;
}

/*Section3*/
.flex-workflow-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color:#261A21;
  width:80vw;
  margin:0 auto;
}

.request-buttons {
  color:#27CCE1;
  transition-duration: 500ms;
  cursor:pointer;
}

.request-buttons:hover {
  color:#262A21;
  transition-duration: 500ms;
  cursor:pointer;
}

/*Section4*/
.flex-card-container {
  display: flex;
  flex-direction: row;
  margin:0 auto;
  flex-wrap: nowrap;
  width:100vw;
  margin:0 auto 50px auto;

}

@media screen and (max-width:700px){
  .flex-card-container {
    margin-top:1050px;
  }
}

/*Section5*/
.product-flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

/*Section6*/
.bonddroid-flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height:100%;
  width:80vw;
  margin:0 auto;
}

.bonddroid-flex-column-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.custom-button {
  padding:20px 25px;
  background-color: #27CCE1;
  color:white;
  font-size:16px;
  display:block;
  margin:0 auto;
  outline:0;
  border:1px solid #27CCE1;
  text-decoration: none;
  transition-duration: 500ms;
  cursor: pointer;
  font-weight:700;
}

.custom-button:hover {
  background-color: #FFFFFF;
  color:#27CCE1;
}

/*DRIFT*/
.drift-widget-welcome-online {
  bottom: 55px !important;
  right: 60px !important;
}

.drift-widget-welcome-expanded-online {
  bottom: 45px !important;
  right: 50px !important;
}