.row-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 90vw;
    margin: 0 auto;
}

@media(min-width: 1601px) {
    .row-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 95vw;
        margin: 0 auto;
    }
}
