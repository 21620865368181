.developers-container {
    display: flex;
    flex-direction: row;
}

.toggle-container {
    flex-grow: 1;
}

.content-container {
    flex-grow: 3;
}