.asset-classes-column-container {
  display: flex;
  flex-direction: column;
}

.asset-classes-row-container {
  padding-top:.7em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em;
}
.button-style {
  padding:6px 12px;
  width:150px;
  font-size:12px;
  outline:0;
  cursor:pointer;
  /* font-family: 'Avenir Next LT Pro Demi Condensed'; */
}

.quote-button-1 {
  background-color: #27CCE1;
  transition-duration: 500ms;
  border: 1px solid #27CCE1;
  color:white;
}

.quote-button-2 {
  transition-duration: 500ms;
  background-color:#16BB96;
  border: 1px solid #16BB96;
  color:white;
}

.quote-button-3 {
  transition-duration: 500ms;
  background-color:#8E9FFD;
  border: 1px solid #8E9FFD;
  color:white;
}

.quote-button-1:hover {
  background-color:white;
  border: 1px solid #27CCE1;
  transition-duration: 500ms;
  color:#27CCE1;

}

.quote-button-2:hover {
  background-color:white;
  border: 1px solid #16BB96;
  transition-duration: 500ms;
  color:#16BB96;
}
.quote-button-3:hover {
  background-color:white;
  border: 1px solid #8E9FFD;
  color:#8E9FFD;
  transition-duration: 500ms;
}

input.round-checkbox {
  appearance: none;
  border:1px solid rgb(0,0,0);
  border-radius: 100px;
  height:20px;
  width:20px;
  background-color: rgb(255,255,255);
  outline:0;
  cursor:pointer;
}

input.round-checkbox:checked {
  background-image: url('../../../images/Shared/check.svg');
}


.cells-flex-container {
  display: flex;
}

.cells-small-flex-container {
  display: flex;
  flex-direction: column;
}

/*Mobile*/
@media screen and (max-width:699px) {
  .asset-classes-row-container {
    padding-top:.7em;
    display: flex;
    flex-direction: column;
    margin:0 auto;
    width:75vw;
    margin-bottom: 1em;
  }

  .asset-heading {
    display:block;
    width:80vw;
    margin:0 auto
  }
}
