.footer {
  height:336px;
  background-color: #261A21;
  width: 100vw;
  margin-top:50px;
}

.footer-links-container {
  padding-top:24px;
  display: flex;
  flex-direction: row;
}

.footer-contact-flex-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.footer-reserved-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top:40px;
}
/* Footer Responsive */
.footer-responsive {
  height:auto;
  background-color: #261A21;
  width: 100vw;
  margin-top:-100px;
  padding-bottom:20px;
  justify-content: center;
}

.footer-responsive-links {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}