.plancontainer-container {
  display: flex;
  flex-direction: row;
  margin-left: 4vw;
}

.quandl-button {
  background:rgba(243,87,56,0.90);
  border:1px solid rgba(243,87,56,0.90);
  box-shadow:0 5px 5px 0 rgba(38,26,33,0.17);
  border-radius:15px;
  cursor:pointer;
  outline:0;
  color:#fff;
  text-align:center;
  height:30px;
  width:178px;
  font-size:16px;
  font-weight: 700;
}

.quandl-button:hover{
  color:rgba(243, 87, 56, 0.90);
  background:#fff;
  transition-duration: 500ms;
}

.quandl-button-small {
  background:rgba(243,87,56,0.90);
  border:1px solid rgba(243,87,56,0.90);
  box-shadow:0 5px 5px 0 rgba(38,26,33,0.17);
  border-radius:15px;
  cursor:pointer;
  outline:0;
  color:#fff;
  text-align:center;
  height:30px;
  width:190px;
  font-size:16px;
  font-weight: 700;
}

.quandl-button-small:hover{
  color:rgba(243, 87, 56, 0.90);
  background:#fff;
  transition-duration: 500ms;
}

/* 
@media only screen and (min-height: 1000px) {
  body {
    background-color: lightblue;
  }
} */