.event-hero-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    height:100%;
    width:270px;
}

@media screen and (max-width:600px){
    .event-hero-container {
        margin:0 auto;
        text-align:center;
    }
}