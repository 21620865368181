.press-item-container {
    display:flex;
    flex-wrap: nowrap;
    width:60%;
    margin:0 auto;
    margin-bottom:50px;
    padding-bottom:50px;
    border-bottom: 1px solid rgba(38,26,33, .2);
    
}

@media screen and (max-width:1166px) {
    .press-item-container {
        width:80%;
    }
}

.social-container {
    display:flex;
}

.twitter-container {
    /* margin-top:-10px; */
    display:flex;
    align-items:center;
}

@media screen and (min-width:500px) and (max-width:1000px) {
    .press-item-container{
        flex-direction: column;
        /* text-align: center; */
    }

    .social-container {
        justify-content: center;
    }
}

.twitter-custom-button {
    margin-top:8px;
    margin-left:20px;
}

@media screen and (max-width:499px) {
    .press-item-container{
        flex-direction: column;
        /* text-align: center; */
    }
    .social-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .twitter-custom-button {
        margin-left: 0;
    }

    .twitter-container {
        margin-top:8px;
    }
}